export type DataEntry = {
  date: string;
  description: string;
  status: string;
};

export const data: DataEntry[] = [
  {
    date: "2023-10-31",
    description: "South elevator",
    status: "down",
  },
  {
    date: "2023-11-01",
    description: "All elevators",
    status: "up",
  },
  {
    date: "2023-11-02",
    description: "North elevator",
    status: "down",
  },
  {
    date: "2023-11-03",
    description: "Stairs #2+3 down",
    status: "down",
  },
  {
    date: "2023-11-05",
    description: "All elevators",
    status: "up",
  },
  {
    date: "2023-11-07",
    description: "All elevators (no response to fobs)",
    status: "down",
  },
  {
    date: "2023-11-08",
    description: "All elevators",
    status: "up",
  },
  {
    date: "2023-11-25",
    description: "South elevator",
    status: "down",
  },
  {
    date: "2023-11-27",
    description: "All elevators",
    status: "up",
  },
  {
    date: "2023-11-28",
    description: "South elevator",
    status: "down",
  },
  {
    date: "2023-11-30",
    description: "Luxer lockers",
    status: "down",
  },
  {
    date: "2023-12-07",
    description: "Resident garage",
    status: "down",
  },
  {
    date: "2023-12-13",
    description: "Fob elevator 1+2, courtyard doors",
    status: "down",
  },
  {
    date: "2023-12-16",
    description: "Fob elevator 1+2, courtyard doors",
    status: "up",
  },
  {
    date: "2023-12-16",
    description: "South elevator",
    status: "down",
  },
  {
    date: "2023-12-18",
    description: "South elevator",
    status: "up",
  },
  {
    date: "2023-12-28",
    description: "Courtyard doors",
    status: "down",
  },
  {
    date: "2023-12-28",
    description: "South elevator + lobby elevator down, no sign",
    status: "down",
  },
  {
    date: "2023-12-28",
    description: "Door to gym, can't scan",
    status: "down",
  },
  {
    date: "2024-01-07",
    description: "All working.",
    status: "up",
  },
  {
    date: "2024-02-27",
    description: "Resident garage door getting repaired.",
    status: "down",
  },
  {
    date: "2024-03-01",
    description: "South elevator down.",
    status: "down",
  },
  {
    date: "2024-03-04",
    description: "South elevator up, resident garage door still down.",
    status: "down",
  },
  {
    date: "2024-03-18",
    description: "Elevator #1, west, down.",
    status: "down",
  },
  {
    date: "2024-03-18",
    description: "Elevator #1, west, up. Resident garage door still down.",
    status: "down",
  },
  {
    date: "2024-03-19",
    description:
      "Air conditioning broken in gym. Resident garage door still down.",
    status: "down",
  },
  {
    date: "2024-04-16",
    description:
      "Resident garage door now working. Air conditioning still broken in gym, but all elevators now working. ",
    status: "up",
  },
  {
    date: "2024-04-17",
    description:
      "Just kidding, email for resident door is wrong. Garage door does not open for most vehicles. Gym air conditioning broken and now leaking heavily from the ceiling.",
    status: "down",
  },
  {
    date: "2024-04-17",
    description:
      "In addition to previous update, fobs are not working for most doors.",
    status: "down",
  },
  {
    date: "2024-04-21",
    description: "All systems working.",
    status: "up",
  },
];
// Remember, always two digit numbers, otherwise mobile will give NaN.

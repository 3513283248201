import { Analytics } from "@vercel/analytics/react";
import { FaArrowUp, FaArrowDown, FaCalendarAlt, FaBan } from "react-icons/fa";
import {
  Badge,
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import "./App.css";
import { data } from "./data";

const firebaseConfig = {
  apiKey: "AIzaSyBR3tsztGGo-P2qiKr966N8EES0ilL4ZZk",
  authDomain: "windsor-2ff4d.firebaseapp.com",
  projectId: "windsor-2ff4d",
  storageBucket: "windsor-2ff4d.appspot.com",
  messagingSenderId: "73169801694",
  appId: "1:73169801694:web:25a894827898ba46988d4f",
  measurementId: "G-4GNNVT7MNW",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const analytics = getAnalytics(app);

function App() {
  const [areWorking, setAreWorking] = useState(false);

  const [daysUp, setDaysUp] = useState(0);
  const [daysDown, setDaysDown] = useState(0);

  const [userInput, setUserInput] = useState("");
  const [userSubmissions, setUserSubmissions] = useState<string[]>([]);

  const totalDays = daysUp + daysDown;

  const percentBroken = (daysDown / totalDays) * 100;

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "user-input"), (snapshot) => {
      setUserSubmissions(
        snapshot.docs.map((doc) => doc.data().description as string)
      );
    });

    return unsub;
  }, []);

  const uploadUserInput = async (input: string) => {
    console.log(`uploading ${input} to firestore`);
    const pendingRef = doc(collection(db, "user-input"));
    await setDoc(pendingRef, {
      description: input,
      timestamp: Date.now(),
      timestampReadable: new Date(Date.now()).toLocaleString("en-US", {
        timeZone: "America/Chicago",
      }),
    });
  };

  useEffect(() => {
    let sortedData = [...data].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    let upTime = 0;
    let downTime = 0;

    sortedData.forEach((entry, index) => {
      let currentDate = new Date(entry.date);
      let nextDate =
        index < sortedData.length - 1
          ? new Date(sortedData[index + 1].date)
          : new Date();
      let diffDays = Math.ceil(
        (nextDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
      );

      if (entry.status === "up") {
        upTime += diffDays;
      } else {
        downTime += diffDays;
      }
    });

    setDaysUp(upTime);
    setDaysDown(downTime);
  }, []);

  const rows = data
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .map((entry) => (
      <Tr key={entry.date}>
        <Td whiteSpace={"normal"}>
          <Text fontWeight="light">{entry.date}</Text>
        </Td>
        <Td whiteSpace={"normal"}>
          <Tooltip label={entry.description} placement="top" hasArrow>
            <Text fontWeight="light" wordBreak={"break-word"}>
              {entry.description}
            </Text>
          </Tooltip>
        </Td>
        <Td>
          <Badge colorScheme={entry.status === "up" ? "green" : "red"}>
            {entry.status === "up" ? "Working" : "Broken"}
          </Badge>
        </Td>
      </Tr>
    ));

  return (
    <Box
      p={8}
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={areWorking ? "green" : "red"}
    >
      <Analytics />
      <VStack spacing={8} align="center" justify="center">
        <Text fontSize="24" color="white" fontWeight="light">
          Is all of Windsor South Lamar's infrastructure working?
        </Text>
        <Heading fontSize="128" color="white">
          {areWorking ? "YES" : "NO"}
        </Heading>

        <StatGroup
          p={4}
          borderRadius="md"
          bg="white"
          boxShadow="md"
          w={["90%", "80%", "70%"]}
          m="auto"
        >
          <Stat
            textAlign="center"
            px={4}
            py={2}
            borderRight="1px"
            borderColor="gray.200"
          >
            <StatLabel>
              <FaBan />
            </StatLabel>
            <StatNumber>{`${percentBroken.toFixed(2)}%`}</StatNumber>
            <Box as="span" color="red.500" fontSize="md">
              % of Days Broken
            </Box>
          </Stat>
          <Stat
            textAlign="center"
            px={4}
            py={2}
            borderRight="1px"
            borderColor="gray.200"
          >
            <StatLabel>
              <FaCalendarAlt />
            </StatLabel>
            <StatNumber>{totalDays}</StatNumber>
            <Box as="span" color="gray.500" fontSize="md">
              Days Tracked
            </Box>
          </Stat>
          <Stat
            textAlign="center"
            px={4}
            py={2}
            borderRight="1px"
            borderColor="gray.200"
          >
            <StatLabel>
              <FaArrowUp />
            </StatLabel>
            <StatNumber>{daysUp}</StatNumber>
            <Box as="span" color="green.500" fontSize="md">
              Days Up
            </Box>
          </Stat>
          <Stat textAlign="center" px={4} py={2}>
            <StatLabel>
              <FaArrowDown />
            </StatLabel>
            <StatNumber>{daysDown}</StatNumber>
            <Box as="span" color="red.500" fontSize="md">
              Days Down
            </Box>
          </Stat>
        </StatGroup>
        <Card p={5}>
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <VStack>
              <FormLabel>Is something broken?</FormLabel>
              <HStack>
                <Input
                  placeholder="What is it?"
                  value={userInput}
                  onChange={(event) => {
                    setUserInput(event.target.value);
                  }}
                />
                <Button
                  colorScheme="blue"
                  isDisabled={userInput.length < 5}
                  onClick={async () => {
                    try {
                      await uploadUserInput(userInput);
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  Submit
                </Button>
              </HStack>
              <FormHelperText>
                Your submission will be added to the website after review.
              </FormHelperText>
            </VStack>
          </FormControl>
        </Card>
        <Card p={5}>
          <VStack>
            <Heading>Pending Submissions</Heading>
            {userSubmissions.map((submission) => (
              <Card key={submission} p={5} shadow="md" bg="gray.200">
                <Text>{submission}</Text>
              </Card>
            ))}
          </VStack>
        </Card>
        <TableContainer overflowY="auto" maxHeight="80vh" maxW="90vw">
          <Table bg="white">
            <Thead bg="whiteAlpha.800">
              <Tr>
                <Th>Date</Th>
                <Th>Description</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>{rows}</Tbody>
            <TableCaption bg="whiteAlpha.800">Past Status</TableCaption>
          </Table>
        </TableContainer>
      </VStack>
    </Box>
  );
}

export default App;
